<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">User</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <Form @submit="submitForm"
              :validation-schema="data.schema"
              v-slot="{ errors }">
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 mb-3">
                        <div class="autocomplete-wrapper">
                            <div class="input-group search-box">
                                <Field class="form-control"
                                       type="email"
                                       name="email"
                                       placeholder="User"
                                       v-model="data.formData.email"
                                       @keydown.down="navigateDown"
                                       @keyup.up="navigateUp"
                                       @keydown.enter.prevent="enterSelectedValue"
                                       @input="startTypingEvent"/>

                                <button class="btn btn-outline-secondary" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-search" viewBox="0 0 16 16">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                    </svg>
                                </button>
                            </div>
                            <div v-if="data.list.length > 0 && data.search"
                                 id="autocompleteList" v-on:scroll="handleScrolledTopToBottom"
                                 class="dropdown-search-result-wrapper custom-scrollbar">
                                <a v-for="(item, index) in data.list"
                                   :key="index"
                                   class="dropdown-item"
                                   :class="{'active':index==data.activeIndex}"
                                   href="#"
                                   @click.prevent="selectAutocomplete(item)">
                                    {{ item.email }}
                                </a>
                            </div>
                            <span class="text-danger">{{ errors.email || serverFormError.email }}</span>
                        </div>
                    </div>
                    <div v-if="data.formData.email && data.selectedUser.id == ''" class="col-12 mb-3">
                        <div class="form-check">
                            <input name="inviteOnly" class="form-check-input" type="checkbox"
                                   v-model="data.formData.inviteOnly"/>
                            <label class="form-control-label">
                                Sent invitation only
                            </label>
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-control-label">Select Role </label>
                        <Multiselect
                            v-model="data.setRoles"
                            placeholder="Select Roles"
                            mode="tags"
                            :options="userRoleNames"
                            :searchable="true">
                        </Multiselect>
                        <span class="text-danger">{{ serverFormError.roles_id || data.roleError }}</span>
                    </div>
                    <template v-if="!data.formData.inviteOnly && data.formData.email">
                        <div class="col-12 mb-3">
                            <label class="form-control-label">Full Name</label>
                            <Field type="text"
                                   name="name"
                                   v-model="data.formData.name"
                                   class="form-control form-control-sm"
                                   placeholder="Full Name"/>
                            <span class="text-danger">{{ errors.name || serverFormError.name }}</span>
                        </div>
                        <div class="col-12 mb-3">
                            <label class="form-control-label">Username</label>
                            <Field type="text"
                                   name="username"
                                   v-model="data.formData.username"
                                   class="form-control form-control-sm"
                                   placeholder="Username"/>
                            <span class="text-danger">{{ errors.username || serverFormError.username }}</span>
                        </div>
                        <div class="col-12 mb-3">
                            <label class="form-control-label">Mobile</label>
                            <Field type="text"
                                   name="mobile"
                                   v-model="data.formData.mobile"
                                   class="form-control form-control-sm"
                                   placeholder="Mobile"/>
                            <span class="text-danger">{{ errors.mobile || serverFormError.mobile }}</span>
                        </div>
                        <div class="col-12 mb-3">
                            <label class="form-control-label">Password</label>
                            <password name="password"
                                      class="form-control form-control-sm"
                                      v-model="data.formData.password"
                                      placeholder="Password"/>
                            <span class="text-danger">{{ errors.password || serverFormError.password }}</span>
                        </div>
                        <div class="col-12 mb-3">
                            <label class="form-control-label">Confirm Password</label>
                            <password name="c_password"
                                      class="form-control form-control-sm"
                                      v-model="data.formData.c_password"
                                      placeholder="Password"/>
                            <span class="text-danger">{{ errors.c_password || serverFormError.c_password }}</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary" :disabled="$store.state.pageLoader">Submit</button>
            </div>
        </Form>
    </div>
</template>

<script>
import {computed, reactive, watch} from "vue";
import {useStore} from "vuex";
import * as Yup from "yup";
import {Form, Field} from "vee-validate";
import Multiselect from '@vueform/multiselect';
import Password from "@/common/components/input/Password"
import HelperFunction from "@/common/helpers"

export default {
    name: "AddEditUser",
    props: {
        formError: {
            type: Object,
            required: false,
            default: {}
        },
        formData: {
            type: Object,
            required: false,
            default: {}
        }
    },
    components: {
        Password,
        Form,
        Field,
        Multiselect
    },
    emits: ['submitForm'],
    setup(props, {emit}) {
        const store = useStore();
        const { debounceEvent } = HelperFunction()
        let data = reactive({
            list: [],
            setRoles: [],
            search: true,
            selectedUser: {},
            roles: [],
            roleError: '',
            schema: null,
            activeIndex: -1,
            formData: {
                email: '',
                id: '',
                inviteOnly: true,
                roles_id: '',
                name: '',
                mobile: '',
                password: '',
                c_password: '',
                username: '',
                company_id: '',
            }
        })
        data.list = []
        data.currentPage = 1
        data.lastPage = 1
        const company = store.getters.getSelectedBoard

        /** computed properties **/
        const userRoleNames = computed(() => {
            let setRoles = []
            store.getters.getRoles.map(function (item) {
                let optionData = {value: item.id, label: item.name, disabled: true | false};
                setRoles.push(optionData);
            });
            return setRoles
            //return store.getters.getRoles.map(item => item.name);
        });
        const selectedCompany = computed(() => store.getters.getSelectedBoard);
        const serverFormError = computed(() => {
            let output = {};
            Object.keys(props.formError).forEach((key) => {
                if (props.formError[key]) {
                    output[key] = props.formError[key][0]
                }
            });
            return output;
        })

        /** watch properties **/
        watch(() => data.formData.inviteOnly, (value) => {
            if (!value) {
                data.schema = Yup.object().shape({
                    name: Yup.string().required(),
                    username: Yup.string().required(),
                    email: Yup.string().email().required(),
                    mobile: Yup.number().required('Mobile Number is required'),
                    password: Yup.string()
                        .min(6, "Password must be at least 6 characters")
                        .required('Password is required'),
                    c_password: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                });
            } else {
                data.schema = Yup.object().shape({
                    email: Yup.string().email().required(),
                });
            }
        }, {immediate: true})

        watch(() => props.formData, (value) => {
            if (value) {
                data.formData = {...data.formData, ...value};
                data.setRoles = data.formData.roles ? data.formData.roles.map(item => item.id) : []
            }
        }, {immediate: true})
        /** watch properties end **/

        /** methods **/
        const autocomplete = (scroll = false) => {
            data.currentPage = scroll ? data.currentPage : null
            const url = `${vueConfig.User.UsersFilterWithPaginationEndPoint}=${data.formData.email}&company_id=${company.id}&max_count=10&page=${data.currentPage}`;
            data.search = true;
            if (data.formData.email.length > 2) {
                store.dispatch('getUsersByEmail', {
                    'action': url
                }).then(response => {
                    data.lastPage = response.entries.last_page
                    if (response.paginate && scroll) 
                        data.list.unshift( ...response.entries.data)
                    else if (response.paginate)
                        data.list = response.entries.data
                    else if (!response.paginate)
                        data.list = response.entries
                }).catch(error => {
                    console.log(error);
                })
            } else {
                data.list = []
            }
        }

        // DEBOUNCE REQUEST FOR 500ms
        const startTypingEvent = debounceEvent(() => autocomplete())

        function handleScrolledTopToBottom(e) {
            // SCROLL BOTTOM CHECK AND EXECUTE FUNCTION
            if ((e.target.offsetHeight + e.target.scrollTop + 1) >= e.target.scrollHeight && data.currentPage < data.lastPage) {
                data.currentPage++;
                autocomplete(true);
            }
        }

        const selectAutocomplete = (obj) => {
            data.selectedUser = obj;
            data.formData.inviteOnly = obj.id ? true : false;
            data.formData.email = obj.email;
            data.formData.id = obj.id;
            data.search = false;
        }

        const submitForm = () => {
            data.roleError = '';
            data.formData.roles_id = '';
            data.formData.company_id = selectedCompany.value.id ? selectedCompany.value.id : null;
            data.setRoles.map(item => {
                if (data.setRoles) {
                    if (data.formData.roles_id) data.formData.roles_id += ',' + item;
                    else data.formData.roles_id = item;
                }
            })
            if (data.formData.roles_id) emit('submitForm', data.formData);
            else data.roleError = 'Role is required';
        }

        const navigateDown = () => {
            if (data.activeIndex < data.list.length - 1 && data.activeIndex > -2) {
                data.activeIndex++;
                let autocompleteList = document.getElementById('autocompleteList');
                let sH = autocompleteList.scrollHeight;
                let aH = autocompleteList.offsetHeight;

                if (sH > aH && data.activeIndex > 0) {
                    //need to adjust scroll height
                    let adS = data.activeIndex == data.list.length - 1 ? (sH - aH) : autocompleteList.scrollTop + (sH - aH) / (data.list.length);
                    autocompleteList.scrollTo(0, adS)
                }
            }
        }

        const navigateUp = () => {
            if (data.activeIndex >= 1) {
                data.activeIndex--;
                let autocompleteList = document.getElementById('autocompleteList');
                let sH = autocompleteList.scrollHeight;
                let aH = autocompleteList.offsetHeight;
                if (sH > aH) {
                    //need to adjust scroll height
                    let adS = data.activeIndex == 0 ? 0 : autocompleteList.scrollTop - (sH - aH) / (data.list.length);
                    autocompleteList.scrollTo(0, adS)
                }
            }
        }

        const enterSelectedValue = () => {
            let obj = data.list[data.activeIndex];
            selectAutocomplete(obj);
            data.activeIndex = -1;
        }
        /** methods end**/

        return {
            data,
            selectAutocomplete,
            userRoleNames,
            submitForm,
            serverFormError,
            navigateDown,
            navigateUp,
            enterSelectedValue,
            selectedCompany,
            startTypingEvent,
            handleScrolledTopToBottom
        }
    }
}
</script>
<style scoped>
.autocomplete-wrapper {
    position: relative;
}

.dropdown-search-result-wrapper {
    padding: 0.8rem 0;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    z-index: 100;
    background: #fff;
    left: 0;
}

.dropdown-search-result-wrapper .dropdown-item {
    font-size: 95% !important;
    color: #898989;
    padding: 0.5rem 1rem !important;
}

.dropdown-search-result-wrapper .dropdown-item.active {
    color: #ffffff !important;
    background-color: #5c76fa !important;
}

.dropdown-search-result-wrapper .dropdown-item.selected {
    color: #ffffff !important;
    background-color: #5c76fa !important;
}

.dropdown-search-result-wrapper .dropdown-item.selected .check-sign {
    display: block;
}

.dropdown-search-result-wrapper .dropdown-item:hover {
    background-color: #5c76fa !important;
    color: #ffffff !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
    background: #5c76fa;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb:hover {
    background-color: #a3b4f9;
}

</style>
